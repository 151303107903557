
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/agencies/Agencies.vue";
import ModalNewAgency from "@/components/agencies/NewAgencyModal.vue";
import ModalDeleteAgency from "@/components/agencies/DeleteAgency.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import _ from "lodash";
import SolanaServices from "@/core/services/SolanaServices";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { Modal } from "bootstrap";
import { Field, ErrorMessage } from "vee-validate";


export default defineComponent({
  name: "Agencies",
  components: {
    Datatable,
    ModalNewAgency,
    ModalDeleteAgency,
    Field,
    ErrorMessage,
  },
  props: {
    dashboard: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const loading = ref<boolean>(true);
    const keywords = ref<string>("");
    const estados = ref<string[]>([]);
    const searhByState = ref<string>("Estado");
    const searhByCity = ref<string>("Ciudad");
    const showing = ref<number>(10);
    let searchWord = ref<string>("");
    const route = useRoute();
    const path = computed(() => route.path);
    const hasAgencies = ref<boolean>(false);
    const headerConfig = ref([
      {
        name: "Nombre",
        key: "name",
        sortable: true,
      },
      {
        name: "Estado",
        key: "state",
        sortable: true,
      },
      {
        name: "Ciudad",
        key: "city",
        sortable: true,
      },
      {
        name: "Domicilio",
        key: "address",
        sortable: true,
      },
      {
        name: "Colonia",
        key: "colonia",
        sortable: true,
      },
      {
        name: "C.P.",
        key: "cp",
        sortable: true,
      },
      {
        name: "Acciones",
        key: "actions",
      },
    ]);
    const tableData1 = ref<any>([]);
    const links = ref<any>([]);
    const total = ref<number>(0);
    const agencyId = ref<number>(0);
    const from = ref<number>(0);
    const to = ref<number>(0);
    const type = ref<string>("new");
    const statesCat = ref<any>([]);
    const citiesCat = ref<any>([]);
    const noResults = ref<boolean>(false);

    const finishAction = async (evt) => {
      if (evt.type == "close" || evt.type == "deleteDismiss") {
        type.value = "new";
        agencyId.value = 0;
      } else {
        if (evt.type == "save") {
          Swal.fire({
            text: "Agencia creada con éxito!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        if (evt.type == "delete") {
          Swal.fire({
            text: "Agencia eliminada con éxito!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        if (evt.type == "update") {
          Swal.fire({
            text: "Agencia actualizada con éxito!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        type.value = "new";
        agencyId.value = 0;
        total.value = 0;
        tableData1.value.splice(0);
        await getAgencies();
        await getCiudades();
      }
    };

    const editAgency = async (idAgency) => {
      agencyId.value = idAgency;
      type.value = "edit";
      console.log(agencyId.value);
    };

    const deleteAgency = (idAgency) => {
      agencyId.value = idAgency;
      type.value = "delete"
    };

    const getPagination = async (link) => {
      tableData1.value.splice(0);
      let urlProd = link.url;
      if(urlProd.search('seminuevossolana') > 0){
        urlProd = urlProd.replace('http', 'https');
      }
      await getAgencies(urlProd);
    };

    const beInStates = (idState) => {
      let exist = true;
      statesCat.value.forEach((value, ind) => {
        if (value.id == idState) {
          exist = false;
        }
      });
      return exist;
    };

    const getAgencies = async (page = "", setpagination = false) => {
      loading.value = true;
      
      if ((page != "" && searchWord.value != "" && searhByState.value == "Estado" && searhByCity.value == "Ciudad")) {
          searchAll("name", searchWord.value, page);
      } else if (page != "" && searhByState.value != "Estado" && searchWord.value == "" && searhByCity.value == "Ciudad") {
          searchAll("state", searhByState.value, page)
      } else if ( page != "" && searhByCity.value != "Ciudad"  && searchWord.value == "" && searhByState.value == "Estado") {
          searchAll("city", searhByCity.value, page);
      } else if (setpagination == true && searchWord.value != "" && searhByState.value == "Estado" && searhByCity.value == "Ciudad") {
          searchAll("name", searchWord.value);
      } else if (setpagination == true && searhByState.value != "Estado" && searchWord.value == "" && searhByCity.value == "Ciudad") {
          searchAll("state", searhByState.value)
      } else if ( setpagination == true && searhByCity.value != "Ciudad"  && searchWord.value == "" && searhByState.value == "Estado") {
          searchAll("city", searhByCity.value); 
      } else {
        try {
        let url = "";
        if (page == "") {
          url =
            process.env.VUE_APP_API_URL +
            "/api/agencies?showing=" +
            showing.value;
        } else {
          url = page; // + "?showing=" + showing.value;
        }
        const { data } = await axios.get(url);
        if (data.success) {
          total.value = data.data.agency.total;
          links.value = data.data.agency.links;
          let agencias = data.data.agency.data;
          from.value = data.data.agency.from;
          to.value = data.data.agency.to;
          agencias.forEach(function (value) {
            tableData1.value.push({
              id: value.id,
              name: value.name,
              state: value.state,
              city: value.city,
              address: value.address,
              colonia: value.colonia,
              cp: value.cp,
            });
          });
          loading.value = false;
        } else {
          loading.value = false;
        }
      } catch (error) {
        console.log(error);
      }
      } 
      
    };

    const getCiudades = async () => {
      citiesCat.value = [];
      const { data } = await SolanaServices.getCiudades();
      citiesCat.value = data.data;
    };

    const getEstados = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/states";
        const { data } = await axios.get(url);
        estados.value = data.data.state;
      } catch (error) {
        console.log(error);
      }
    };

    const getAgencyEstates = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/agency-states";
        const { data } = await axios.get(url);
        estados.value.forEach((element: any) => {
          if (data.data.includes(element.name)) {
            statesCat.value.push({
              id: element.id,
              state: element.name,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    const searchState = async () => {
      searchWord.value = "";
      searhByCity.value = "Ciudad";
      if (searhByState.value != "Estado") {
        searchAll("state", searhByState.value);
      } else {
        //hasAgencies.value = false;
        noResults.value = false;
        tableData1.value.splice(0);
        links.value.splice(0);
        await getAgencies();
      }
    };
    
    const searchCity = async () => {
      searchWord.value = "";
      searhByState.value = "Estado";
      if (searhByCity.value != "Ciudad") {
        searchAll("city", searhByCity.value);
      } else {
        //hasAgencies.value = false;
        noResults.value = false;
        tableData1.value.splice(0);
        links.value.splice(0);
        await getAgencies();
      }
    };

    const searchItems = _.debounce(async function () {
      searhByCity.value = "Ciudad";
      searhByState.value = "Estado";
      loading.value = true;
      if (searchWord.value != "") {
        searchAll("name", searchWord.value);
      } else {
        //hasAgencies.value = false;
        noResults.value = false;
        tableData1.value.splice(0);
        links.value.splice(0);
        await getAgencies();
      }
      loading.value = false;
    }, 500);

    const searchAll = async (type: string, value: string, linkUrl = "") => {
      if (type == "city" && value == "Ciudad") {
        //hasAgencies.value = false;
        noResults.value = false;
        tableData1.value.splice(0);
        links.value.splice(0);
        await getAgencies();
      } else {
        try {
          tableData1.value.splice(0);
          links.value.splice(0);
          let url =
            process.env.VUE_APP_API_URL +
            "/api/search/agencies?showing=" +
            showing.value;
          if ( linkUrl != ""){
            url = linkUrl
          } 
          const { data } = await axios.post(url, {
            type: type,
            value: value,
          });
          total.value = data.data.total;
          links.value = data.data.links;
          //total.valye = data.data.total;
          from.value = data.data.from;
          to.value = data.data.to;
          let agencies = data.data.data;
          
          if (agencies.length > 0) {
            agencies.forEach(function (value) {
              tableData1.value.push({
                id: value.id,
                name: value.name,
                state: value.state,
                city: value.city,
                address: value.address,
                colonia: value.colonia,
                cp: value.cp,
              });
            });
          } else {
            //hasAgencies.value = true;
            noResults.value = true;
            total.value = 0;
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const showingSet = async () => {
      tableData1.value.splice(0);
      links.value.splice(0);
      await getAgencies("", true);
    };

    const clearModal = () => {
      const modal = new Modal(document.getElementById("newAgencyModal"));
      modal.show();
      type.value = "new";
    };

    const downloadReport = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/reporte/agencies?showin=1000";
        
        if (searchWord.value != "") {
          url += "&type=name&value=" + searchWord.value;
        }

        if (searhByState.value != "Estado") {
          url += "&type=state&value=" + searhByState.value;
        }

        if (searhByCity.value != "Ciudad") {
          url += "&type=city&value=" + searhByCity.value;
        }
        
        const { data } = await axios.get(url);
        let csvContent = "data:text/csv;charset=utf-8," + data;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ListadoAgencias.csv");
        link.click();
        //window.open(encodedUri);
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      const { data } = await SolanaServices.getTipoRol();
      let tipoRol = data.data[0].type;
      if (tipoRol != "Múltiples agencias" && tipoRol != "Ciudad") {
        await getAgencies();
        await getEstados();
        await getAgencyEstates();
        await getCiudades();
      }
    });

    return {
      headerConfig,
      loading,
      keywords,
      finishAction,
      getAgencies,
      tableData1,
      links,
      getPagination,
      editAgency,
      agencyId,
      deleteAgency,
      total,
      estados,
      getEstados,
      searhByState,
      searchState,
      searhByCity,
      searchCity,
      searchItems,
      searchWord,
      searchAll,
      showing,
      showingSet,
      from,
      to,
      clearModal,
      downloadReport,
      hasAgencies,
      type,
      statesCat,
      beInStates,
      citiesCat,
      noResults,
      getCiudades,
      getAgencyEstates,
      path,
    };
  },
});
